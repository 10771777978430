import { useContext, useEffect, useState } from 'react';

import { AuthenticationAPI, Cookie } from '../services';
import { INVALID_SESSION } from '../constants/StatusConstants';
import { UserContext } from '../context/UserContext';
import * as Utils from '../utils';
import { TerminationErrors } from './utility';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux';
import { oktaModalSliceActions } from '../redux/gtw-okta-modal-slice';

const TIME_BEFORE_SESSION_EXPIRES = 5 * 60 * 1000;

export const useRefreshSessions = () => {
  const userContext = useContext(UserContext);
  const dispatch = useAppDispatch();
  const timeoutModalConfig = useAppSelector((state: RootState) => state.gtwOktaModalSliceData.timeoutModalConfig);

  const [showSessionExpireDialog, setShowSessionExpireDialog] = useState<boolean>(false);

  let interval: ReturnType<typeof setInterval>;

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      clearInterval(interval);
      setShowSessionExpireDialog(false);
      dispatch(oktaModalSliceActions.setTimeoutModalConfig({ isOpened: false, remindLater: false }));
    }
  }, [window.location.pathname]);

  const onLogout = () => {
    clearInterval(interval);
    Utils.navigateToLogin();
  };

  const onKeepLogin = async (
    extendGatewaySession: boolean,
    extendTokenSession: boolean,
    extendOktaSession: boolean,
  ) => {
    setShowSessionExpireDialog(false);

    if (extendGatewaySession) {
      userContext.extendGatewaySession();
    }
    if (extendTokenSession) {
      const res = await AuthenticationAPI.refreshSession();
      if (!res.success) {
        console.info('failed to refresh okta token');
        dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.FailedToRefreshOktaToken));
        Cookie.setMessageID(TerminationErrors.FailedToRefreshOktaToken);
        return showModals(false, undefined);
      }
    }
    if (extendOktaSession) {
      const res = await userContext.extendOktaSession();
      if (!res.success) {
        console.info('failed to refresh okta session');
        dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.FailedToRefreshOktaSession));
        Cookie.setMessageID(TerminationErrors.FailedToRefreshOktaSession);
        return showModals(false, undefined);
      }
    }
  };

  const showModals = (remindLater: boolean, oktaSessionExpiresAt?: string) => {
    if (oktaSessionExpiresAt) {
      setShowSessionExpireDialog(true);
    } else if (!timeoutModalConfig.isOpened) {
      dispatch(oktaModalSliceActions.setTimeoutModalConfig({ isOpened: true, remindLater }));
    }
  };

  useEffect(() => {
    interval = setInterval(() => {
      if (window.location.pathname === '/auth/login') {
        clearInterval(interval);
        return;
      }
      if (showSessionExpireDialog || timeoutModalConfig.isOpened) {
        return;
      }

      const oktaTokenExpiresAt = Cookie.getOktaTokenExpiresAt();
      const oktaSessionExpiresAt = Cookie.getOktaSessionExpirationTime();
      const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();
      const sessionNotificationsCounter = Cookie.getSessionNotificationsCounter();

      if (oktaTokenExpiresAt === 'null') {
        console.info('okta token extend session limit is reached');
        dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.OktaTokenExtendSessionLimitReached));
        Cookie.setMessageID(TerminationErrors.OktaTokenExtendSessionLimitReached);
        return showModals(false, undefined);
      }

      if (oktaTokenExpiresAt && gatewaySessionExpiresAt) {
        const timeNow = new Date().getTime();
        const gatewaySessionDiffInMilliseconds = Number(gatewaySessionExpiresAt) - timeNow;
        const tokenDiffInMilliseconds = Number(oktaTokenExpiresAt) - timeNow;

        if (gatewaySessionDiffInMilliseconds <= 0) {
          Cookie.setStatus(INVALID_SESSION);
          console.info('gateway session expired', gatewaySessionDiffInMilliseconds);
          return onLogout();
        }
        if (oktaSessionExpiresAt) {
          const oktaSessionDiffInMilliseconds = Number(oktaSessionExpiresAt) - timeNow;
          if (oktaSessionDiffInMilliseconds < 30 * 1000) {
            onKeepLogin(false, false, true);
          }
        }
        if (tokenDiffInMilliseconds < 30 * 1000) {
          onKeepLogin(false, true, false);
        }
        if (
          (!sessionNotificationsCounter && gatewaySessionDiffInMilliseconds < TIME_BEFORE_SESSION_EXPIRES) ||
          gatewaySessionDiffInMilliseconds < 30 * 1000
        ) {
          const isRemindLaterBtnAvailable = gatewaySessionDiffInMilliseconds > 30 * 1000;
          if (!(isRemindLaterBtnAvailable && oktaSessionExpiresAt)) {
            dispatch(oktaModalSliceActions.setTerminationCode(TerminationErrors.GatewaySessionExpired));
            Cookie.setMessageID(TerminationErrors.GatewaySessionExpired);
          }
          showModals(isRemindLaterBtnAvailable, oktaSessionExpiresAt);
        }
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [timeoutModalConfig, showSessionExpireDialog]);

  return {
    showSessionExpireDialog,
    onLogout,
    onKeepLogin,
  };
};
