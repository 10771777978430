import { useEffect, useRef, useState } from 'react';
import { AuthenticationAPI } from '../services';
import { clearCurrentTimeout, navigateToLogin } from '../utils';
import { getOktaTokenInfo } from '../services/GatewayManagement';
import Cookie from '../services/Cookie';

export const refreshOktaToken = async (): Promise<void> => {
  const { success } = await AuthenticationAPI.refreshSession();
  if (!success) {
    return await navigateToLogin();
  }
};
// time in mili seconds before session expires
const REFRESH_BEFORE = 5 * 60 * 1000;
export const useOktaTokenRefresh = () => {
  const [tokenTimeout, setTokenTimeout] = useState<number>(0);
  const timeout = useRef<number | null>(null);

  useEffect(() => {
    (async () => {
      await checkToken();
    })();
  }, []);

  useEffect(() => {
    clearCurrentTimeout(timeout.current);
    if (!tokenTimeout) return;
    timeout.current = window.setTimeout(async () => {
      await refreshOktaToken();
      await checkToken();
    }, tokenTimeout);
    return () => clearCurrentTimeout(timeout.current);
  }, [tokenTimeout]);

  const checkToken = async (): Promise<void> => {
    const { expiresAt, active } = await getTokenInfo();
    if (!active || !expiresAt) {
      return await navigateToLogin();
    }
    const timeDiff = getTimeDiff(expiresAt);
    if (timeDiff < 0) {
      return await navigateToLogin();
    }
    setTokenTimeout(timeDiff);
  };

  const getToken = (): string | null => {
    const token = Cookie.getOktaToken();
    if (!token) {
      return null;
    }
    return token;
  };

  const getTokenInfo = async (): Promise<{ expiresAt: string | undefined; active: boolean | undefined }> => {
    const token = getToken();
    if (!token) {
      return { expiresAt: undefined, active: undefined };
    }
    const tokeType = 'access_token';
    const { expiresAt, active } = await getOktaTokenInfo(token, tokeType);
    return { expiresAt, active };
  };

  const getTimeDiff = (expiresAt: string): number => {
    // expiresAt is in seconds
    const expiresInTime = parseInt(expiresAt, 10) * 1000;
    const nowDateTime = new Date().getTime();
    return expiresInTime - nowDateTime - REFRESH_BEFORE;
  };
};
