/**
 * Experimental functionality, will be removed in future.
 */
import Honeybadger from '@honeybadger-io/js';
import { navigate } from 'gatsby';
import config from '../../config';

/**
 * Requirement: redirect users from https://stewardpartners.miradorgateway.com to https://connect.stewardpartners.com
 * It is temporary solution, must be removed in future.
 */
export function useStewardCustomRedirect() {
  if (config.GATSBY_USE_STEWARD_CUSTOM_REDIRECT !== 'true') {
    return;
  }

  try {
    const OLD_URL = 'https://stewardpartners.miradorgateway.com';
    const NEW_URL = 'https://connect.stewardpartners.com';
    const REDIRECT_MESSAGE = `This domain will expire soon, you will be redirected to: "${NEW_URL}"`;

    const fullUrl = window.location.origin + window.location.pathname + window.location.search;

    const isOldUrlOpened = fullUrl.includes(OLD_URL);

    if (!isOldUrlOpened) {
      return;
    }

    alert(REDIRECT_MESSAGE);

    const redirectUrl = fullUrl.replace(OLD_URL, NEW_URL);

    navigate(redirectUrl);
  } catch (error) {
    console.log(error);

    Honeybadger.notify('Steward Custom Redirect Error', {
      name: 'Steward custom redirect error',
      tags: `gateway-ui, steward-custom-redirect-error`,
      context: { error },
    });
  }
}
