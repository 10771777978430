import * as CookieConstants from './CookieConstants';
import * as StatusConstants from './StatusConstants';
import * as Types from './Types';
import * as LocalStorageConstants from './LocalStorage';
import * as IconHelper from './IconHelper';
import DummyFile from './DummyFile';
import * as Enums from './Enums';
import * as SpaceConstants from './SpaceConstants';
import _ from 'lodash';

const VerificationTypes = {
  auth: 'Authentication',
  enroll: 'Enrollment',
};

const CustomizeTypes = {
  update: 'Update',
  create: 'create',
};

const UserRoleTypes = {
  advisor: 'ADVISOR',
  client: 'CLIENT',
  custom: 'CUSTOM',
  admin: 'ADMIN',
};

const RolePages = {
  custom: 'Custom',
  enableModules: 'enableModules',
  dashboardShortcuts: 'dashboardShortcuts',
};

const QuicksiteKeys = {
  K1Dashboard: 'K1 Dashboard',
  offlineDataManagement: 'Offline Data Management',
  privateInvestmentDesk: 'Private Investment Desk',
};

const ModuleKeys: Types.IModuleKeys = {
  dashboard: 'dashboard',
  insights: 'insights',
  marketUpdates: 'market_updates',
  marketWidget: 'market-widget',
  salesforceIntegration: 'salesforce_integration',
  teamDirectory: 'team_directory',
  documentLibrary: 'document_library',
  twitter: 'twitter',
  tools: 'tools',
  commonLinks: 'common_links',
  formalDisclosure: 'formal-disclosure',
  footerDisclosure: 'footer-disclosure',
  zoomAdvisor: 'zoom-advisor',
  bookAdvisor: 'book-advisor',
  calendar: 'calendar',
  landingRoute: 'landing-route',
  requestSetups: 'request-setups',
  mainLayout: 'main-layout',
  sidebarLayout: 'sidebar-layout',
  appShortcuts: 'shortCuts',
  featuredPost: 'featuredPosts',
  dashBoardHighlights: 'dashBoardHighlights',
  employeeDirectory: 'employee_directory',
  upcomingEvents: 'upcomingEvents',
  keyPlanningAreas: 'keyPlanningAreas',
  myClients: 'my-clients',
  spaces: 'spaces',
  reli: 'reli',
  quickSight: 'quicksight',
  portfolioOverview: 'portfolioOverview',
  cognicor: 'CogniCor-AI-Meeting-Assistant',
  menuBuilder: 'navigation_menu',
  quicksights: 'quicksights',
  linkCategories: 'linkCategories',
};

const dashboardModule = [
  {
    title: 'Dashboard Main Layout Sorting',
    description: '',
    key: ModuleKeys.mainLayout,
  },
  {
    title: 'Dashboard Insight Highlights',
    description: '',
    key: ModuleKeys.dashBoardHighlights,
  },
  {
    title: 'Dashboard Sidebar Layout Sorting',
    description: '',
    key: ModuleKeys.sidebarLayout,
  },
  {
    title: 'Quick Links',
    description: 'Choose up to three subcategories of quick links',
    key: ModuleKeys.linkCategories,
  },
  {
    title: 'Market Updates',
    description: 'RSS feed pulling updated market, technology, and economy news.',
    key: ModuleKeys.marketUpdates,
  },
  {
    title: 'Twitter',
    description: 'Use Twitter to get the latest internal and external news.',
    key: ModuleKeys.twitter,
  },
  {
    title: 'Market Overview',
    description: 'Show market overview chart. Click here to configure widget and paste the embed code below.',
    key: ModuleKeys.marketWidget,
    link: 'https://www.tradingview.com/widget/market-overview/',
  },
  {
    title: 'Upcoming Events',
    description: 'Show upcoming events on dashboard.',
    key: ModuleKeys.upcomingEvents,
  },
];

const CognicorSubModule = {
  title: 'CogniCor AI Meeting Assistant',
  description: 'Show CogniCor AI Meeting Assistant on Dashboard page.',
  key: ModuleKeys.cognicor,
};

const MenuItemsModule = [
  {
    title: 'Menu items',
    description: 'Customize your sidebar menu items names and order.',
    key: ModuleKeys.menuBuilder,
  },
];

const RequestsModule = [
  {
    title: 'Requests',
    description: 'Allow users to initiate servise requests.',
    key: ModuleKeys.requestSetups,
  },
];

const QuicksightsModule = [
  {
    title: 'Quicksights',
    description: 'Chose quicksights to be addded to your role.',
    key: ModuleKeys.quicksights,
  },
];

const FooterModule = [
  {
    title: 'Book a Meeting',
    description: 'Allow your team to schedule a meeting with their advisors.',
    key: ModuleKeys.bookAdvisor,
  },
  {
    title: 'Video Call Your Advisors',
    description: 'Allow your team to video call their advisors.',
    key: ModuleKeys.zoomAdvisor,
  },
  {
    title: 'Formal Disclosure',
    description: 'Create a formal disclosure page that will link in the footer.',
    key: ModuleKeys.formalDisclosure,
  },
  {
    title: 'Footer Disclosure',
    description: 'Display legal information in footer that pertains to your company.',
    key: ModuleKeys.footerDisclosure,
  },
  {
    title: 'Custom Landing Page',
    description: 'Set a new Page to land after login instead of the default one.',
    key: ModuleKeys.landingRoute,
  },
];

const moduleCollectionBuilder = (
  COGNICOR_PROJECT_ID: string | undefined,
  INSTANCE_TYPE: string | undefined,
): { name: Enums.SettingsModuleTypeEnum; data: Types.ModuleDataTypeArray }[] => {
  const _moduleDataArray: Types.ModuleDataKeyValueCollection[] = [];

  const _currentDashboardModule = _.cloneDeep(dashboardModule);
  const _currentMenuItemsModule = _.cloneDeep(MenuItemsModule);
  const _currentRequestsModule = _.cloneDeep(RequestsModule);
  const _currentQuicksightsModule = _.cloneDeep(QuicksightsModule);
  const _currentFooterModule = _.cloneDeep(FooterModule);

  if (COGNICOR_PROJECT_ID) {
    _currentDashboardModule.push(CognicorSubModule);
  }

  _moduleDataArray.push(
    { name: Enums.SettingsModuleTypeEnum.currentDashboardModule, data: _currentDashboardModule },
    { name: Enums.SettingsModuleTypeEnum.currentMenuItemsModule, data: _currentMenuItemsModule },
    { name: Enums.SettingsModuleTypeEnum.currentRequestsModule, data: _currentRequestsModule },
  );

  if (INSTANCE_TYPE && INSTANCE_TYPE !== Enums.InstanceTypeEnum.prod_client) {
    _moduleDataArray.push({
      name: Enums.SettingsModuleTypeEnum.currentQuicksightsModule,
      data: _currentQuicksightsModule,
    });
  }

  _moduleDataArray.push({ name: Enums.SettingsModuleTypeEnum.currentFooterModule, data: _currentFooterModule });

  return _moduleDataArray;
};

const RoutesToNavigate = {
  DASHBOARD: '/dashboard',
  INSIGHTS: '/insights',
  TOOLS: '/tools',
  AUTH_USER_GROUPS: '/auth/user-groups',
  CLIENT: '/my-clients',
  TEAM: '/team',
  CALENDARS: '/calendar',
  DOCUMENTS: '/documents',
  REQUEST: '/request-dashboard',
  EMPLOYEE_DIRECTORY: '/employee-directory',
  ALTS_DASHBOARD: '/alts-dashboard',
  SPACES: '/spaces',
  RELI: '/reli',
  PORTFOLIO_ANALYTICS: '/portfolio-analytics',
};

const drawerWidth = 260;

export {
  CookieConstants,
  StatusConstants,
  VerificationTypes,
  CustomizeTypes,
  Types,
  UserRoleTypes,
  RolePages,
  QuicksiteKeys,
  LocalStorageConstants,
  ModuleKeys,
  IconHelper,
  DummyFile,
  RoutesToNavigate,
  Enums,
  SpaceConstants,
  drawerWidth,
  moduleCollectionBuilder,
};
