import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SalesforceRequestCase } from '../constants/Types';
import { getSubmittedRequestsList } from '../services/GatewayManagement';

interface IInitialState {
  submittedRequestsList: SalesforceRequestCase[];
}

const initialState: IInitialState = {
  submittedRequestsList: [],
};

export const setSubmittedRequestsListAsync = createAsyncThunk(
  'gtw-requests-slice/setSubmittedRequestsListAsync',
  async () => {
    return await getSubmittedRequestsList();
  },
);

export const gatewayRequestsSlice = createSlice({
  name: 'gtw-requests-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSubmittedRequestsListAsync.fulfilled, (state, action) => {
      state.submittedRequestsList =
        action.payload.success && action.payload.submittedRequestsList ? action.payload.submittedRequestsList : [];
    });
  },
});

export const gatewayRequestsSliceActions = gatewayRequestsSlice.actions;

export default gatewayRequestsSlice.reducer;
