import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  timeoutModalConfig: {
    isOpened: boolean;
    remindLater: boolean;
  };
  terminationCode: string;
}

const initialState: IInitialState = {
  timeoutModalConfig: { isOpened: false, remindLater: false },
  terminationCode: '',
};

export const oktaModalSlice = createSlice({
  name: 'okta-modal-slice',
  initialState,
  reducers: {
    setTimeoutModalConfig(state, action) {
      state.timeoutModalConfig = action.payload;
    },
    setTerminationCode(state, action) {
      state.terminationCode = action.payload;
    },
  },
});

export const oktaModalSliceActions = oktaModalSlice.actions;

export default oktaModalSlice.reducer;
