import React from 'react';
import { DateTime } from 'luxon';
import { PageProps } from 'gatsby';
import { Row } from 'react-table';

import { RoleMenuItem } from '../Layouts/Types';
import { DataType2, MidasFieldCalcType } from '../components/Reli/constants';
import { IThirdPartyIntegrations } from './ThirdPartyIntegrationsConstants';
import { Enums } from '.';
import { ICommonLink } from './CommonLinksConstants';

export interface ISyncUsersInfoResponse {
  success: boolean;
  data?: ISyncUsersInfoResponseData;
  error?: string;
}

export interface ISyncUsersInfoResponseData {
  working: boolean;
}

export interface ISyncNewUsersResponse {
  success: boolean;
  data?: ISyncNewUsersResponseData;
  error?: string;
}

export interface ISyncNewUsersResponseData extends ISyncUsersInfoResponseData {
  lastSyncUsersAmount: number;
}

export interface IGlobalCosmicConfig {
  gateway_primary_color: string;
  gateway_session_max_hours: number;
  okta_link: string;
  amazon_quicksight_dashboard_id: string;
  email_count_restriction_from_user_per_day: number;
  needs_setup: boolean;
  allow_update_recovery_password_question: boolean;
  document_library?: IDocumentLibraryType;
}
export interface RSSFeedType {
  url: string;
  label: string;
  icon: string;
}

export enum fieldEnteringModeEnum {
  onTab = 'onTab',
  onShiftTab = 'onShiftTab',
  onEnter = 'onEnter',
  clickedOutside = 'clicked-outside',
  addSubfield = 'add-subfield',
  editSubfield = 'edit-subfield',
}

export interface SFvalidationResponseFile {
  result: string;
  guid: string;
}

export interface SFvalidationResponse {
  success: boolean;
  error?: string;
  data?: {
    sharefile: {
      job_id: string;
      expression: string;
      files: SFvalidationResponseFile | SFvalidationResponseFile[];
    };
    offset: number;
  };
}

export interface IChosenCalendars {
  title: string;
  calendar_id: string | number;
  service_token: string;
}

export interface IUserLevelSorting {
  useCustom: boolean;
  layoutArray: CategoryType[];
}

export interface CategoryType {
  title: string;
  full_width?: boolean;
  weight?: number;
  visibility?: boolean;
  chosenCalendars?: IChosenCalendars[];
  linkCategories?: { slug: string }[];
}

export interface RequestType {
  id: string;
  title: string;
}

export interface LandingRouteType {
  title: string;
  description: string;
  key: string;
}

export interface RoleType {
  id: string;
  label: string;
  title: string;
  is_admin: boolean;
  is_default: boolean;
  logo: string;
  shortcuts: string[];
  permissions: string[];
  colors: ColorType[];
  type: string;
  external_twitter_handles: string;
  internal_twitter_handles: string;
  full_disclosure: string;
  footer_disclosure: string;
  market_widget_settings: IMarketWidgetConfig;
  rss_feeds: RSSFeedType[];
  insight_categories: CategoryType[];
  role_type: string;
  landing_route: string;
  success: boolean;
  main_layout_sorting: CategoryType[];
  sidebar_layout_sorting: CategoryType[];
  request_setups: RequestType[];
  role_amazon_qs_dashboard_id: string;
  slug: string;
  navigation_menu: RoleMenuItem[];
}

export interface CalendarType {
  id?: string;
  calendar_unique_key?: string;
  title: string;
  calendar_id: string | number;
  is_visible: boolean;
  is_main_calendar: boolean;
  role?: string;
  api_token?: string;
}

export interface RoleObject {
  id: string;
}

export interface ColorType {
  color: string;
  type: string;
}

export interface GatewaySetupProps {
  location: {
    state: {
      type: string;
      role: Partial<RoleType>;
      appliedPages?: string[];
    };
  };
}

export interface InsightPostProps {
  location: {
    state: {
      post: Post;
    };
  };
}

export interface GatewayApps {
  id: string;
  is_shortcut: boolean;
  label: string;
  link: string;
  logo: string;
}

export interface SecurityQuestionProps {
  question: string;
  questionText: string;
}

export type SharefileOptions = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  appcp: string;
  apicp: string;
  subdomain: string;
  access_files_folders: boolean;
  modify_files_folders: boolean;
  admin_users: boolean;
  admin_accounts: boolean;
  change_my_settings: boolean;
  web_app_login: boolean;
};

export type Files = {
  Children?: ChildrenEntity[] | null;
  Info: Info;
  FileCount?: number | null | undefined;
  Name: string;
  CreatorNameShort: string;
  'odata.metadata': string;
  Id: string;
};

export type ChildrenEntity = {
  FileCount?: number | null;
  Name: string;
  CreationDate: string;
  FileSizeBytes: number;
  CreatorNameShort: string;
  'odata.metadata': string;
  Id: string;
  ParentId?: string;
};

export type Info = {
  IsAHomeFolder: boolean;
  'odata.metadata': string;
};

export interface TweetUser {
  id: string;
  name: string;
  profile_image_url: string;
  username: string;
}

export interface Tweet {
  author_id: string;
  created_at: string;
  id: string;
  text: string;
  user: TweetUser;
  media?: string[];
}

export interface Advisor {
  degree_list: string;
  description: string;
  email_address: string;
  name: string;
  title: string;
  photo: {
    imgix_url: string;
    url: string;
  };
  zoom_url?: string;
  meeting_url?: string;
  primary_phone?: string;
  department?: string;
  location?: string;
}

export interface IClient {
  email: string;
  name: string;
  teamName?: string;
  photo: {
    imgix_url: string;
    url: string;
  };
  third_party_integrations: IThirdPartyIntegrations[];
  id: string;
}

export interface IClientTableData {
  id: string;
  name: string;
  email: string;
  teamName: string;
  third_party_integrations: IThirdPartyIntegrations[];
}

export interface Team {
  name: string;
  advisors: Advisor[];
  clients?: IClient[];
}

export interface ThemeStyleProps {
  secondary: string;
  primary: string;
}

export interface IParentDiv {
  colors: {
    readonly primary: string;
    readonly secondary: string;
  };
  width?: string;
}
export interface ModuleDataType {
  title: string;
  description: string;
  key: string;
  link?: string;
}

export interface ModuleDataTypeArray extends Array<ModuleDataType> {}

export interface ModuleDataKeyValueCollection {
  name: Enums.SettingsModuleTypeEnum;
  data: ModuleDataTypeArray;
}

export interface ModalDialogProps {
  isOpen: boolean;
  title: string;
  inputs: {
    placeholder: string;
    label: string;
    value: string;
    id: string;
    pattern?: string;
    isSelect?: boolean;
    options?: { id: string; value: string }[];
  }[];
  variable: string;
  id: string;
  type: string;
  folderId: string;
}

export interface MenuItemSubitems {
  isDynamic?: boolean;
  title: string;
  link: { to: string; iframeId?: string };
}
export interface RequestSetupItem extends MenuItemSubitems {
  chosenRequestSetup: IRequestToSubmit;
}
export interface MenuItem {
  title: string;
  link: { to: string; iframeId?: string };
  icon: JSX.Element;
  selected: boolean;
  subItems?: MenuItemSubitems[] | null;
  id: string;
}

// Request Types
export interface IServiceDetails {
  id: string;
  name: string;
  topics: string[];
}

export interface IServiceDetailResponse {
  success: boolean;
  requestType?: IServiceDetails;
  status?: string;
}

export interface IServiceCreateData {
  requestTopics: string[];
  requestName: string;
  requestEmail: string;
  requestBody: string;
  requestSubject: string;
}

export interface ICreateServiceTypePayload {
  requestId: string;
  requestTypeName: string;
  requestTopics: string[];
  requestBody: string;
  requestEmail: string;
  requestSubject: string;
  requestName: string;
}

export interface IAxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
  message?: string;
}

export interface IRequestConfigResponse {
  success: boolean;
  requestsConfig?: {
    id: string;
    title: string;
    config_type: string;
    config: any;
  }[];
}

export type SalesforceDynamicConnection = {
  refreshToken: string;
  clientSecret: string;
  redirectUri: string;
  instanceUrl: string;
  authMethod: string;
  clientId: string;
  version: string;
};

export interface IConfigData {
  objectName: string;
  connectionConfig: string;
}

export interface ICRMConfigData {
  id: string;
  title: string;
  config: string;
  config_type: string;
  isActive?: boolean;
}

export interface IDataFromAddEditCRMModal {
  id: string;
  config: string;
  title: string;
  add: boolean;
}

export interface IDataFromAddEditRequestModal {
  request_config: string;
  request_template: string;
  title: string;
  id: string;
  roles: string[];
  add: boolean;
}

export enum RequestStatusesEnum {
  new = 'New',
  pending = 'Pending',
  review = 'Review',
  closed = 'Closed',
}

export interface IRequestStatuses {
  new: number;
  pending: number;
  review: number;
  closed: number;
}

export interface IAdminSectionRequestsList {
  CaseNumber: string;
  LastModifiedDate: string;
  Status: RequestStatusesEnum;
  Subject: string;
  Priority: RequestPriorityEnum;
  Setup: string;
}

export enum RequestPriorityEnum {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}
export interface SalesforceRequestCaseComments {
  CommentBody: string;
  Id: string;
  CreatedBy: string;
  CreatedDate: string;
  IsDeleted: boolean;
}
export interface SalesforceRequestCase {
  Id: string;
  IsDeleted: boolean;
  CaseNumber: string;
  OwnerId: string;
  CreatedById: string;
  LastModifiedById: string;
  SystemModstamp: string;
  SuppliedName: string;
  SuppliedEmail: string;
  Type: string;
  RecordTypeId: string;
  RequestsSetupId: string;
  Setup_Name__c: string;
  Status: RequestStatusesEnum;
  Reason: string | null;
  Origin: string;
  Priority: RequestPriorityEnum;
  Subject: string;
  Description: string;
  IsClosed: boolean;
  IsEscalated: boolean;
  ClosedDate: string | null;
  CreatedDate: string;
  LastModifiedDate: string;
  LastViewedDate: string;
  LastReferencedDate: string;
  CaseComments: SalesforceRequestCaseComments[];

  ContentDocumentLinks: {
    Id: string;
    Title: string;
    CreatedDate: string;
    FileExtension: string;
    FileType: string;
  }[];
}

export interface IRequestTypeData {
  id: string;
  title: string;
  request_config: { id: string; title: string };
  request_template: { id: string; title: string };
  roles: { id: string; title: string }[];
}

export interface IReqTypeData {
  title: string;
  roles: string[];
  request_config: string;
  request_template: string;
}

export interface IReqServicesData {
  comments: any;
  createdAt: string;
  gatewayId: any;
  id: string;
  requestBody: string;
  requestEmail: string;
  requestName: string;
  requestPriority: string | null;
  requestSubject: string | null;
  requestTopics: string[] | string;
  status: string;
  updatedAt: string;
}

export interface IRequestResponse {
  success: boolean;
  error?: string;
}

export type ReqType = {
  id: string;
  name: string;
  topics: string[];
  requestId: string;
  hasConfig: boolean;
  services?: IReqServicesData[];
};

export enum RequestTemplateTypes {
  salesforce = 'Salesforce',
}

export enum RequestFormItemsTypes {
  textInput = 'Text input',
  number = 'Number',
  dropdown = 'Dropdown',
  date = 'Date',
  checkbox = 'Checkbox',
  textArea = 'Text area',
  switch = 'Switch',
  attachments = 'Attachments',
  tbd = '',
}

export interface SalesforceTypeConfiguration {
  RecordTypeId: string;
}

export enum RequestTemplateTableColumsEnum {
  name = 'fieldName',
  type = 'fieldType',
  order = 'fieldOrder',
  options = 'fieldOptions',
  actions = 'actions',
  sfKey = 'Saleforce key',
}

export interface RequestTemplateFormItem {
  order: string | number;
  type: RequestFormItemsTypes;
  item: { key: string; title: string; options: string[] | null };
  id?: string;
}

export interface RequestTemplate {
  title: string;
  template_type: RequestTemplateTypes;
  configurations: SalesforceTypeConfiguration;
  form_items: RequestTemplateFormItem[];
  id: string;
}

export interface IRequestToSubmit {
  id: string;
  title: string;
  request_template: RequestTemplate;
}

export interface IRequestWithOptionalIds {
  title: string;
  template_type: RequestTemplateTypes;
  configurations: SalesforceTypeConfiguration;
  form_items: RequestTemplateFormItem[];
  id?: string;
}

export type AttachmentsEntity = {
  url: string;
  title: string;
  media_title?: string;
};

export type BlogContentVisibility = {
  content: string;
  id: string;
  slug: string;
  status: string;
  thumbnail: string;
  title: string;
  type: string;
};

export type Post = Partial<{
  id: string;
  slug: string;
  title: string;
  content: string;
  date: DateTime;
  dateString: string;
  modified_at: string;
  isFeatured: boolean;
  blog_content_visibility: BlogContentVisibility[];
  categories?: string[] | null;
  author: string;
}>;

export type PostPayload = Omit<Post, 'blog_content_visibility'> & {
  blog_content_visibility: string[];
};

export type Posts = {
  success: boolean;
  posts: Post[];
};

export type LinkCategories = {
  success: boolean;
  linkCategoriesArray: ICommonLink[];
};

export type LoginType = {
  success: boolean;
  status?: string;
  accessToken?: string;
  message?: string;
  redirectURL?: string;
  error?: string;
};

export interface IMarketWidgetConfig {
  colorTheme: string;
  dateRange: string;
  showChart: boolean;
  locale: string;
  largeChartUrl: string;
  isTransparent: boolean;
  showSymbolLogo: boolean;
  showFloatingTooltip: boolean;
  width: string;
  height: string;
  plotLineColorGrowing: string;
  plotLineColorFalling: string;
  gridLineColor: string;
  scaleFontColor: string;
  belowLineFillColorGrowing: string;
  belowLineFillColorFalling: string;
  belowLineFillColorGrowingBottom: string;
  belowLineFillColorFallingBottom: string;
  symbolActiveColor: string;
  tabs: ITab[];
}

interface ITab {
  title: string;
  symbols: ISymbol[];
  originalTitle: string;
}

interface ISymbol {
  s: string;
  d?: string;
}

export interface IKeyAreasOfPlanning {
  title: string;
  key: string;
  logo: string;
  accountabilityHeading: string;
  firstRow: string;
  secondRow: string;
  thirdRow: string;
  value?: number;
}

export interface Officer {
  name: string;
  title: string;
  degree_list: string;
  email_address: string;
  photo: {
    imgix_url: string | null;
    url: string | null;
  };
  primary_phone: string;
  linked_in_url: string;
  twitter_handle: string;
  zoom_url: string;
  meeting_url: string;
  content: string;
  department: string;
  location: string;
  description: string;
  oktaId: string;
  pin_to_directory?: string[];
}

export interface IUpcomingEventsData {
  id: string;
  unique: string;
  title: string;
  eventname: string;
  description: string;
  location: string;
  organizer: string;
  organizer_email: string;
  date_start: string;
  date_start_time: string;
  date_start_ampm: string;
  date_start_unix: number;
  date_end: string;
  date_end_time: string;
  date_end_ampm: string;
  date_end_unix: number;
  all_day_event: string;
  date_format: string;
  timezone: string;
  reminder: string;
  rrule: string;
  template_id: string;
  color: string;
  custom_data: string;
  link_short: string;
  link_long: string;
  date_create: number;
  date_modified: number;
  updated_times: string;
}

interface RSSFeedItem {
  content: string;
  contentSnippet: string;
  guid: string;
  isoDate: string;
  link: string;
  pubDate: string;
  title: string;
}

export interface RSSFeed {
  label: string;
  data: RSSFeedItem[];
}

export interface IIconsKeys {
  [key: string]: any;
}

export interface IUserLevelShortcuts {
  useCustom: boolean;
  shortcuts: string[];
}

export interface IUserLevelMarketOverview {
  useCustom: boolean;
  config: IMarketWidgetConfig;
}

export interface IUserLevelApps {
  title: string;
  apps: string[];
  isDefault: boolean;
}

export type User = {
  success: boolean;
  id: string;
  oktaId: string;
  email_address: string;
  name: string;
  sharefile: { clientId: string; subdomain: string; url: string };
  advisors: Advisor[];
  content: string;
  photo: { imgix_url: string | null; url: string | null };
  title: string;
  linked_in_url: string;
  phone_number: string;
  twitter_handle: string;
  zoom_url?: string;
  meeting_url?: string;
  degree_list?: string;
  timeout_to_extend_session?: number;
  user_dashboard_main_layout_sorting?: IUserLevelSorting[];
  user_dashboard_sidebar_layout_sorting?: IUserLevelSorting[];
  user_level_dashboard_shortcut: IUserLevelShortcuts[];
  user_level_market_overview: IUserLevelMarketOverview[];
  user_tools_organization: IUserLevelApps[];
  third_party_integrations: Partial<IThirdPartyIntegrations[]>;
  user_amazon_qs_dashboard_id: string;
  advisory_team: {
    title?: string;
    id?: string;
  };
  sent_email_times_counter: number;
};

export type App = {
  id: string;
  logo: string;
  label: string;
  link: string;
  is_shortcut: boolean;
};

export type Apps = {
  success: boolean;
  apps: App[];
};

export type Advisors = {
  success: boolean;
  advisors: Partial<Advisor>[];
};

export interface INewPostData {
  title: string;
  posted_date: string;
  blog_content_visibility: string[];
  author: string;
  content: string;
  is_featured: boolean;
  categories: string[];
}

interface IRoleKeyInternals {
  label: string;
  id: string;
  isAdmin?: boolean;
}

export interface IShadowedRole {
  isShadowed: boolean;
  shadowedRole: IRoleKeyInternals;
  assignedRole: IRoleKeyInternals;
}

export interface IStyleProps {
  primary: string;
  secondary: string;
  isShadowed?: boolean;
}

interface ISpaceContentVisibility {
  id: string;
  title: string;
}

export interface ISpaces {
  content: string;
  id: string;
  slug: string;
  space_content_visibility: ISpaceContentVisibility[];
  status: string;
  thumbnail: string;
  title: string;
  modified_at: string;
  subtitle: string;
  color: {
    key: string;
    value: string;
  };
  categories: string[];
  father_spaces?: ISpaces[];
  able_linked_as_parent_or_child?: boolean;
  subspaces?: ISpaces[];
  logo?: ISpaceLogo;
}

export interface ISpaceColor {
  key: string;
  value: string;
}

export interface INewSpaceData {
  title: string;
  subtitle: string;
  space_content_visibility: string[];
  content: string;
  categories: string[];
  subspaces: string[];
  color: ISpaceColor;
  logo: ISpaceLogo;
  slug?: string;
  able_linked_as_parent_or_child?: boolean;
}

export interface IBaseColors {
  colors: {
    primary: string;
    secondary: string;
  };
}

export interface IReusableTableProps extends IBaseColors {
  isAdvisorsTable: boolean;
}

export interface ISingleDocData {
  normalized_data: {
    file_id: string;
    data: IPdfField2[];
  };
}

export interface V4SingleDocData {
  detail: {
    data: {
      document: {
        [key: string]: {
          [key: string]: {
            midas_type: string;
            properties: IPdfField2Property;
            value: string;
          };
        };
      };
    };
  };
}

export interface ISocketResponse {
  data: V4SingleDocData;
  id: string;
  headers: string[];
}

export interface ITableCellsData2 {
  id: string;
  type: string;
  name: string;
  hash: string;
  state: string;
  fields: IPdfField2[];
}

export type IPdfField2Property = {
  error_text: string | null;
  error_type: string | null;
  height: number | null;
  page: number | null;
  raw: string;
  score: number;
  width: number | null;
  x0: number | null;
  y0: number | null;
  x1: number | null;
  y1: number | null;
  __object_root: string;
};

export type IPdfField2 = {
  field_id: string;
  value: string;
  midas_type: string;
  properties?: IPdfField2Property;
  guid?: string;
};

type IPutDeleteCellPropertyAndValue = {
  field_id: string;
  property: null | {
    score: number;
  };
  value: string | number;
  guid: string;
};

export type IPutDeleteCellData = {
  [key: string]: IPutDeleteCellPropertyAndValue;
};

export interface MappedReliErrorInterface {
  error: ResponseObjectInterface;
  statusCode: number;
}

export type IDispatchSetToastify = React.Dispatch<
  React.SetStateAction<{
    type: string;
    msg: string;
    options?: { autoClose?: number; closeOnClick?: boolean; icon?: JSX.Element };
  }>
>;

export interface ResponseObjectInterface {
  success?: boolean;
  error: object | string;
  data?: object;
}

export interface IGetJobResponseData {
  job: string;
  client: string;
  description: string;
  ['create-time']: string;
  files: { file: IJobFileData[] };
}

export interface IStructuredPdfField {
  name: string;
  type: keyof typeof DataType2;
  value: string | null;
  status?: string;
  error: boolean;
  errorText?: string;
  score?: number;
  coordinates?: {
    x0: number;
    y0: number;
    x1: number;
    y1: number;
    height: number;
    width: number;
    page: number;
    object_root: string;
    allCoordinatesArePresent: boolean;
  };
  isFormulaField?: boolean;
  guid?: string;
  id?: string;
}

export interface IMidasFields2 {
  id: string;
  type: keyof typeof DataType2;
  calcType: MidasFieldCalcType;
  description?: string;
}

export interface ITableCellsStructuredData {
  id: string;
  type: string;
  hash: string;
  name: string;
  state: string;
  fields: IStructuredPdfField[];
}

export interface ResponseUnstructuredData {
  id: string;
  data: {
    detail: {
      data: {
        document: {
          [key: string]: {
            [key: string]: {
              midas_type: string;
              properties: IPdfField2Property;
              value: string;
            };
          };
        };
      };
    };
  };
}

export interface ITableCellsStructuredData2 {
  id: string;
  type: string;
  hash: string;
  name: string;
  state: string;
  fields: {
    [key: string]: {
      [key: string]: {
        midas_type: string;
        properties: IPdfField2Property;
        value: string;
      };
    };
  };
}

export interface IJobFileData {
  guid: string;
  hash: string;
  name: string;
  state: string;
  type: string;
  route: string;
}

export interface IJobInfo {
  id: string;
  client: string;
  jobName: string;
  files: { file: IJobFileData[] };
}

export interface IGetJobResponseData {
  '@id': string;
  client: string;
  description: string;
  files: { file: IJobFileData[] };
}

export interface IJobDocumentsIds {
  id: string;
  client: string;
  jobName: string;
  files: ITableCellsStructuredData[];
  initialFiles: ITableCellsStructuredData[];
}

export interface ISpaceVisibilityGroups {
  id: string;
  title: string;
}

export interface IVisibilityGroupsData {
  id: string;
  name: string;
}

export interface IBlogContentGroups extends IVisibilityGroupsData {
  chosen: boolean;
}

export interface IUserContext {
  shadowedRole: IShadowedRole;
  syncNewUsersLoading: boolean;
  syncUsersInfoLoading: boolean;
  isLoading: boolean;
  user: Partial<User>;
  role: Partial<RoleType>;
  apps: Partial<Apps>;
  posts: Partial<Posts>;
  advisors: Partial<Advisors>;
  requestTemplates: RequestTemplate[];
  keysFinancialPlanning: IKeyAreasOfPlanning[];
  recentTransactions: ITransaction[];
  portfolioServiceName: string;
  portfolioData: Partial<IPortfolioAccounting>;
  documentLibrary: IDocumentLibraryType;
  toastifySettings: {
    type: string;
    msg: string;
    options?: { autoClose?: number; closeOnClick?: boolean; icon?: JSX.Element };
  };
  baseUrl: string;
  defaultOktaSessionExpirationHours: number;
  maxGatewaySessionExpirationHours: { success: boolean; value: number };
  linkCategories: Partial<LinkCategories>;
  setLinkCategories: (linkCategories: Partial<LinkCategories>) => void;
  setToastifySettings: React.Dispatch<
    React.SetStateAction<{
      type: string;
      msg: string;
      options?: { autoClose?: number; closeOnClick?: boolean; icon?: JSX.Element };
    }>
  >;
  setSyncNewUsersLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSyncUsersInfoLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => any;
  refreshRole: (showLoading?: boolean, useCache?: boolean) => Promise<void>;
  refreshUser: (showLoading?: boolean) => Promise<void>;
  refreshPosts: (slug?: string) => Promise<void>;
  onUserSetUp: (showLoading?: boolean) => void;
  setPosts: (posts: any) => void;
  activateShadowedRole: (isActive: boolean, roleId: string) => void;
  setRecentTransactions: (d: ITransaction[]) => void;
  setPortfolioServiceName: (s: string) => void;
  setPortfolioData: (d: Partial<IPortfolioAccounting>) => void;
  extendGatewaySession: () => void;
  extendOktaSession: () => Promise<{
    success: boolean;
  }>;
  sortedRows: {
    id: string;
    state: string;
  }[];
  setSortedRows: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        state: string;
      }[]
    >
  >;
  reliFilter: string;
  setReliFilter: React.Dispatch<React.SetStateAction<string>>;
  reliSortOptions: { sortBy: { id: string; desc: boolean | undefined }[] };
  setReliSortOptions: React.Dispatch<React.SetStateAction<{ sortBy: { id: string; desc: boolean | undefined }[] }>>;
  reliPagination: { page: number; rowsPerPage: number } | null;
  setReliPagination: React.Dispatch<React.SetStateAction<{ page: number; rowsPerPage: number } | null>>;
  setRole: React.Dispatch<React.SetStateAction<Partial<RoleType>>>;
}

export interface IRelatedPosts {
  title: string;
  posts: Post[];
}

export type OrderBy = 'id' | 'dateTime' | 'name' | 'status' | 'userName';

export interface IJobFormattedData {
  id: string;
  dateTime: string;
  name: string;
  status: string;
  userName: string;
  client: string;
}

export interface JobServerInfo {
  description: string;
  ['create-time']: string;
  status: string;
  client: string;
  job_id: string;
  upload_user?: string;
}

export interface IChosenCategories {
  title: string;
  chosen: boolean;
}

export type InsightPageProps = Omit<PageProps, 'location'> & {
  location: {
    state: {
      slug: string;
    };
  };
};

export interface Jobs {
  [id: string]: JobServerInfo;
}

export interface ISpaceLogo {
  key: string;
  value: string;
}

export type EnrollmentData = {
  success: boolean;
  email: string;
  password: string;
};

export interface ILoginTypes {
  success: boolean;
  types: string[];
}

export interface IAuthContext {
  customLogo: { success: boolean; logo: string };
  customBgColor: { success: boolean; color: string };
  customFavicon: { success: boolean; favicon: string };
  enrollmentData: Partial<EnrollmentData>;
  clientLoginTypes: ILoginTypes;
  employeeLoginTypes: ILoginTypes;
  refreshEnrollmentData: (enrollmentData?: Partial<EnrollmentData>) => void;
  getCustomLogo: (refresh?: boolean) => void;
  getCustomBgColor: (refresh?: boolean) => void;
  getCustomFavicon: (refresh?: boolean) => void;
}
export interface IModuleKeys {
  [key: string]: string;
}

export interface IRoleSettingsProps {
  isNewRole?: boolean;
  role?: Partial<RoleType>;
}

export interface IExternalURLModalData {
  url: string;
  isOpened: boolean;
  target?: string;
}

export interface IFolderInfo {
  IsAHomeFolder: boolean;
  CanAddFolder: boolean;
  [key: string]: any;
}
export interface IFolder {
  Id: string;
  Name: string;
  Info?: Partial<IFolderInfo>;
  children?: IFolder[] | null;
  [key: string]: any;
}

export interface IVariable {
  name: string;
  value: string;
  id: string;
  type: string;
}

export interface ISubFolder {
  id: string;
  value: string;
  placeholder: string;
  error: boolean;
  helperText?: string;
}

export type SpaceItemType = 'category' | 'space';

export enum SpaceItemEnum {
  space = 'space',
  category = 'category',
}

export type SpacePageType = 'all-spaces' | 'category';

export enum SpacePageTypeEnum {
  category = 'category',
  allSpaces = 'all-spaces',
}

export interface ISpaceGrid {
  pageType: SpacePageType;
  spacesToDisplay: ISpaces[];
  titleMaxLength: number;
  subTitleMaxLength: number;
}

export interface IShareFileFolder {
  Id: string;
  Name: string;
  FileCount?: number;
  Children?: IShareFileFolder[];
  [key: string]: any;
}

export interface DocumentsPageProps {
  location: {
    pathname: string;
    state?: {
      pathname?: string;
      isFile?: boolean;
    };
  };
}

export interface ITransactionField {
  value: string;
  display_name: string;
}

export interface IAssetClasses {
  name: string;
  value: number;
}

export interface IColumnChartData {
  twr_quarterly: number;
  twr_YTD: number;
  twr_trailing: number;
  twr_3y: number;
  twr_5y: number;
  twr_since_inception: number;
  _id: string;
}

type Spx500 = Omit<IColumnChartData, '_id'>;

export interface IPortfolioAccounting {
  asset_classes: IAssetClasses[];
  entity_id: number;
  service_name: string;
  service_type: string;
  transactions: ITransaction[];
  updatedAt: string;
  market_value: number;
  market_value_yesterday: number;
  inception_date: string;
  twr: IColumnChartData;
  spx500: Spx500;
}

export interface ITransaction {
  security: ITransactionField;
  trade_date: ITransactionField;
  transaction_id: string;
  type: ITransactionField;
  value: ITransactionField;
  _id: string;
}

export interface IRecentTransactionsData {
  service_name: string;
  service_type: string;
  transactions: ITransaction[];
}

export interface IRenderMap {
  [key: string]: JSX.Element | null;
}

export interface ReliDocumentType {
  manual: {
    vendor: string;
    class: string;
    train: boolean;
  };
}

export interface IGatewayColorAndTitlePayload {
  spaces_display_name?: string;
  gateway_primary_color?: string;
}
export type Order = 'asc' | 'desc';

export interface TableColumn<T> {
  Header: string;
  accessor: ((d: T) => string) | string;
  id: string;
  sticky?: string;
  Cell?: React.MemoExoticComponent<(...args: any) => JSX.Element> | (([keys]: any) => JSX.Element | string);
  width?: number;
  disableSortBy?: boolean;
  sortType?: (rowA: Row, rowB: Row, id: string, desc: boolean) => number;
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export interface IChartArguments {
  default_value: string;
  id: string;
  values: (string | boolean | null)[];
}

export interface IChartAttributes {
  category: string;
  display_name: string;
  id: string;
  output_type: string;
  usage: string[];
}
export type OktaTokenTypeHint = 'access_token' | 'id_token' | 'refresh_token' | 'device_secret';

export interface BoxUser {
  type: string;
  id: string;
  name: string;
  login: string;
}

export enum BoxItemType {
  folder = 'folder',
  file = 'file',
}

export interface BoxItemGeneralInfo {
  type: BoxItemType;
  id: string;
  name: string;
  created_at?: string;
}

export interface BoxItem {
  id: string;
  name: string;
  created_at: string;
  type: BoxItemType;
  created_by: BoxUser;

  path_collection: {
    total_count: number;
    entries: BoxItemGeneralInfo[];
  };

  item_collection: {
    entries: BoxItemGeneralInfo[];
    total_count: number;
    offset: number;
    limit: number;
  };
}

export interface BoxZipFile {
  name: string;
  items: {
    type: BoxItemType;
    id: string;
  }[];
}

export enum IDocumentLibraryType {
  Box = 'Box',
  Sharefile = 'Sharefile',
}

export enum MessageTypes {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

export interface IDocLibraryFile {
  ItemType: string;
  DisplayName: string;
  CreationDate: string;
  Size: number;
  CreatorName: string;
  'odata.metadata': string;
  ItemID: string;
  ParentID: string;
}

export enum LoginFlowEnum {
  loginFlow = 'login-flow',
  customOkta = 'custom-okta',
  default = 'default',
  codeVerifier = 'code-verifier ',
}

interface EvaIconOptions {
  width?: string;
  height?: string;
  fill?: string;
  class?: string;
  animation?: {
    type?: 'zoom' | 'pulse' | 'shake' | 'flip';
    hover?: boolean;
    infinite?: boolean;
  };
}

type Status = 'Info' | 'Success' | 'Danger' | 'Primary' | 'Warning' | 'Basic' | 'Control';

interface IconProps {
  name: any;
  status?: Status;
  className?: string;
  options?: EvaIconOptions;
}

type IconField = string | IconProps;

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

interface ItemType {
  title: string;
  expanded?: boolean;
  selected?: boolean;
  hidden?: boolean;
  icon?: IconField;
  hasDynamicPath?: boolean;
  url: string;
  link: {
    [k: string]: any;
  };
  group: boolean;
  extras?: {
    position: 'after' | 'before';
    content: any;
  }[];
  children: RequireOnlyOne<ItemType, 'url' | 'children' | 'link' | 'group'>[];
}

export type MenuItemType = RequireOnlyOne<ItemType, 'url' | 'children' | 'link' | 'group'>;
